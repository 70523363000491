import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { Money, DateTime, Percentage } from '../../../shared';
import { CurrencyBalanceSheetActions } from './currencyBalanceSheetActions';
import { SheetValidationStatusContainer } from '../containers/sheetValidationStatusContainer';
import { AccountFlagsList } from './accountFlagsList';
import { currencyBalanceSheet as currencyBalanceSheetPropTypes } from './commonPropTypes';
import { SortedAccountNameValueContainer, SortedAccountIdValueContainer } from '../containers/sortedAccountNameFieldContainer';
import { SortedAccountPriorityValueContainer } from '../containers/sortedAccountPriorityFieldContainer';
import { outOfBoundsClassname } from '../shared/commonHelpers';
import QuickView from '../../../notes/listing/components/quickView';

const SheetAmount = ({ amount, isoCode }) => (
  <div><span style={{ whiteSpace: 'nowrap' }}>{(!isoCode || !amount) ? '-' : Money(amount, isoCode)}</span></div>
);

const CurrencyBalanceSheetRow = (props) => {
  const {
    currencyBalanceSheet, onValidate, onValidateAndSend, isValidating,
    selected, processingGroupId, isRebalancing, onSelect,
    onUnselect, onCreateAccountFlag, currentFilters,
    accountFlagsNotes, onFetchAccountFlagNotes,
  } = props;
  const {
    account: { id, shortName, note },
    client: { id: clientId },
    totalCurrencyExposures,
    totalCurrencyHedges,
    totalCurrencyNetTradeAmounts,
    currencyHedgeRatios,
    checkedAt,
    accountFlags,
  } = currencyBalanceSheet;
  const currencyBalanceSheetId = currencyBalanceSheet.id;

  const handleSelection = (e) => {
    const { checked } = e.target;
    if (checked) {
      onSelect(currencyBalanceSheetId);
    } else {
      onUnselect(currencyBalanceSheetId);
    }
  };

  return (
    <tr data-test-class={`sheetRow${currencyBalanceSheetId}`} data-test-processing-group-id={processingGroupId} className={isRebalancing ? 'rebalancing' : ''}>
      <td className="currency-balance-sheet-borderless-column" />
      <td className="text-right">
        {
          processingGroupId !== 0 && (
            <span className="select-checkbox-container" data-test-id={`sheetSelect${currencyBalanceSheetId}`}>
              <label className="control control--checkbox" htmlFor={`selectRow${currencyBalanceSheetId}`}>
                <input data-test-id={`sheetSelect${currencyBalanceSheetId}`} type="checkbox" checked={selected} id={`selectRow${currencyBalanceSheetId}`} className="reports-config-checkbox" onChange={handleSelection} />
                <span className="control__indicator" />
              </label>
            </span>
          )
        }
      </td>
      <SortedAccountIdValueContainer processingGroupId={processingGroupId} data-test-class="sheetAccountName">
        {id}
      </SortedAccountIdValueContainer>
      <SortedAccountNameValueContainer processingGroupId={processingGroupId} data-test-class="sheetAccountName">
        {`${shortName} - ${currencyBalanceSheetId} `}
      </SortedAccountNameValueContainer>
      <td data-test-id={`sheetExposure${currencyBalanceSheetId}`} className="text-right">
        {
          totalCurrencyExposures.map(totalCurrencyExposure => (
            <SheetAmount key={totalCurrencyExposure.isoCode} {...totalCurrencyExposure} />
          ))
        }
      </td>
      <td data-test-id={`sheetHedge${currencyBalanceSheetId}`} className="text-right">
        {
          totalCurrencyHedges.map(totalCurrencyHedge => (
            <SheetAmount key={totalCurrencyHedge.isoCode} {...totalCurrencyHedge} />
          ))
        }
      </td>
      <td data-test-id={`sheetNetAmount${currencyBalanceSheetId}`} className="text-right">
        {
          totalCurrencyNetTradeAmounts.map(totalCurrencyNetTradeAmount => (
            <SheetAmount
              key={totalCurrencyNetTradeAmount.isoCode}
              {...totalCurrencyNetTradeAmount}
            />
          ))
        }
      </td>
      <td data-test-id={`sheetHedgeRatio${currencyBalanceSheetId}`} className="text-center">
        {
          currencyHedgeRatios.map(({ isoCode, percentage, isOutOfBounds }) => {
            const className = outOfBoundsClassname(isOutOfBounds);
            return (
              <div key={isoCode} className={className}>
                <Percentage percentage={percentage} />
              </div>
            );
          })
        }
      </td>
      <SortedAccountPriorityValueContainer processingGroupId={processingGroupId} data-test-class="sheetStatuses">
        <SheetValidationStatusContainer currencyBalanceSheetId={currencyBalanceSheetId} />
        <AccountFlagsList
          clientId={clientId}
          processingGroupId={processingGroupId}
          currentFilters={currentFilters}
          currencyBalanceSheetId={currencyBalanceSheetId}
          accountFlags={accountFlags}
          data-test-id={`sheetStatuses${currencyBalanceSheetId}`}
          onCreateAccountFlag={onCreateAccountFlag}
          accountFlagsNotes={accountFlagsNotes}
          onFetchAccountFlagNotes={onFetchAccountFlagNotes}
        />
        <div>
          <i className="material-icons currency-balance-sheet-checked-at">
            {I18n.t('icons.check_circle')}
          </i>
          {checkedAt ? <DateTime dateTime={checkedAt} format="D MMMM YYYY, HH:mm:ss" /> : I18n.t('never')}
        </div>
      </SortedAccountPriorityValueContainer>
      <td>
        {
          note && (
            <QuickView note={note} />
          )
        }
      </td>
      <td className="text-right">
        <CurrencyBalanceSheetActions
          currencyBalanceSheet={currencyBalanceSheet}
          isValidating={isValidating}
          isRebalancing={isRebalancing}
          onValidate={onValidate}
          onValidateAndSend={onValidateAndSend}
        />
      </td>
    </tr>
  );
};

CurrencyBalanceSheetRow.propTypes = {
  currencyBalanceSheet: currencyBalanceSheetPropTypes.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onValidateAndSend: PropTypes.func.isRequired,
  isRebalancing: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  processingGroupId: PropTypes.number,
  onCreateAccountFlag: PropTypes.func.isRequired,
  onFetchAccountFlagNotes: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  currentFilters: PropTypes.object.isRequired,
  accountFlagsNotes: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

CurrencyBalanceSheetRow.defaultProps = {
  processingGroupId: null,
};

SheetAmount.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isoCode: PropTypes.string.isRequired,
};

export { CurrencyBalanceSheetRow };
